import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import { FaRegCircle, FaCheckCircle } from "react-icons/fa";
import { useTheme, Checkbox } from "@mui/material";
import ScreenHeader from "../../../components/ScreenHeader";

// Redux
import {
  targetsSortByIsDone,
  targetsSortBy,
  targetsOrderBy,
} from "../../../redux/actions/local";

// Firestore
import { updateUser } from "../../../api/firestore/users";

// Analytics
import { analytics } from "../../../api/firebase";

// Own api
import { Devices } from "../../../api/constants";
import "./style.scss";

const SortingTargets = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentDevice = useSelector((state) => state.local.currentDevice);
  const isTargetsSortByIsDone = useSelector(
    (state) => state.local.isTargetsSortByIsDone
  );
  const isTargetsSortBy = useSelector((state) => state.local.isTargetsSortBy);
  const isTargetsOrderBy = useSelector((state) => state.local.isTargetsOrderBy);

  const handleTargetsSortByIsDone = (property) => {
    dispatch(targetsSortByIsDone(property));
    updateSortingTargetsSettings("targetsSortByIsDone", property);
  };

  const sort = (property) => {
    dispatch(targetsSortBy(property));
    updateSortingTargetsSettings("targetsSortBy", property);
  };

  const order = (property) => {
    dispatch(targetsOrderBy(property));
    updateSortingTargetsSettings("targetsOrderBy", property);
  };

  const btnBackClicked = () => {
    navigate("/settings");
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        navigate("/settings");
        break;
      default:
        break;
    }
  };

  // -------------------------------- Debounce controlled inputs -------------------------------- //
  const updateSortingTargetsSettings = useCallback(
    debounce((property, value) => {
      updateUser({
        [property]: value,
      });
      analytics.logEvent("used_sort_targets");
    }, 300),
    []
  );

  return (
    <div
      className="sorting-targets-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
      }}
    >
      <div className="sorting-targets-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Settings"
          title="Sort Targets"
        />
        <div className="content">
          <label className="title" style={{ color: theme.palette.text.main }}>
            Sort by done
          </label>
          <div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isTargetsSortByIsDone === "ASC"}
                onChange={() => {
                  handleTargetsSortByIsDone("ASC");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  handleTargetsSortByIsDone("ASC");
                }}
              >
                Yes (top)
              </label>
            </div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isTargetsSortByIsDone === "DESC"}
                onChange={() => {
                  handleTargetsSortByIsDone("DESC");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  handleTargetsSortByIsDone("DESC");
                }}
              >
                Yes (bottom)
              </label>
            </div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isTargetsSortByIsDone === "no"}
                onChange={() => {
                  handleTargetsSortByIsDone("no");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  handleTargetsSortByIsDone("no");
                }}
              >
                No
              </label>
            </div>
          </div>

          <label className="title" style={{ color: theme.palette.text.main }}>
            Sort by
          </label>
          <div className="options">
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isTargetsSortBy === "createdAt"}
                onChange={() => {
                  sort("createdAt");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  sort("createdAt");
                }}
              >
                Created time
              </label>
            </div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isTargetsSortBy === "name"}
                onChange={() => {
                  sort("name");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  sort("name");
                }}
              >
                Name
              </label>
            </div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isTargetsSortBy === "tag"}
                onChange={() => {
                  sort("tag");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  sort("tag");
                }}
              >
                Color
              </label>
            </div>
          </div>

          <label className="title" style={{ color: theme.palette.text.main }}>
            Order by
          </label>
          <div className="options">
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isTargetsOrderBy === "ASC"}
                onChange={() => {
                  order("ASC");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  order("ASC");
                }}
              >
                Ascending
              </label>
            </div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isTargetsOrderBy === "DESC"}
                onChange={() => {
                  order("DESC");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  order("DESC");
                }}
              >
                Descending
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SortingTargets);
