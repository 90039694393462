import { firestore, auth } from "../../api/firebase";
import moment from "moment";
import { captureException } from "@sentry/react";

import {
  FETCH_MONTH,
  SET_LOADING_MONTH,
  FETCH_CURRENT_MONTH,
  SET_LOADING_CURRENT_MONTH,
  FETCH_LAST_MONTH,
  SET_LOADING_LAST_MONTH,
} from "../types";

let unsubscribers = [];
let unsubscribersMonth = [];

const fetchMonthSuccess = (month) => ({
  type: FETCH_MONTH,
  payload: month,
});

const setLoadingMonth = (isLoadingMonth) => ({
  type: SET_LOADING_MONTH,
  payload: isLoadingMonth,
});

const fetchCurrentMonthSuccess = (currentMonth) => ({
  type: FETCH_CURRENT_MONTH,
  payload: currentMonth,
});

const setLoadingCurrentMonth = (isLoadingCurrentMonth) => ({
  type: SET_LOADING_CURRENT_MONTH,
  payload: isLoadingCurrentMonth,
});

const fetchLastMonthSuccess = (lastMonth) => ({
  type: FETCH_LAST_MONTH,
  payload: lastMonth,
});

const setLoadingLastMonth = (isLoadingLastMonth) => ({
  type: SET_LOADING_LAST_MONTH,
  payload: isLoadingLastMonth,
});

// TODO consider if pagination is necessary, because Firebase suggests to avoid frequently churning listeners
// https://firebase.google.com/docs/firestore/best-practices#realtime_updates
// But this guy says that it won't cause technical issues (just not the cleanest approach)
// https://stackoverflow.com/a/66979317/8111691
export const fetchMonth =
  (date = moment()) =>
  (dispatch) => {
    try {
      const userUid = auth.currentUser.uid;

      dispatch(setLoadingMonth(true));
      dispatch(fetchMonthSuccess(null));

      const monthSubscriber = firestore
        .collection("months")
        .doc(userUid)
        .collection("months")
        .doc(moment(date).format("YYYYMM"))
        .onSnapshot(
          (snapshot) => {
            if (snapshot.exists) {
              const data = snapshot.data();

              const month = {
                ...data,
                id: snapshot.id, // References the month UID
                ...(data?.date && {
                  date: moment(data.date?.toDate && data.date.toDate())
                    .utc()
                    .utcOffset(moment().utcOffset(), true),
                }),
              };
              dispatch(fetchMonthSuccess(month));
            }
            dispatch(setLoadingMonth(false));
          },
          (error) => {
            dispatch(setLoadingMonth(false));
            captureException("Error fetching month onSnapshot", error);
            console.log("Error fetching month onSnapshot: " + error);
          }
        );
      unsubscribers.push(monthSubscriber);
      unsubscribersMonth.push(monthSubscriber);
    } catch (error) {
      dispatch(setLoadingMonth(false));
      captureException("Error fetching month", error);
      console.log("Error fetching month: " + error);
    }
  };

export const fetchLastMonth = () => (dispatch) => {
  try {
    const userUid = auth.currentUser.uid;

    dispatch(setLoadingLastMonth(true));
    dispatch(fetchLastMonthSuccess(null));

    const lastMonthSubscriber = firestore
      .collection("months")
      .doc(userUid)
      .collection("months")
      .doc(moment().subtract(1, "months").format("YYYYMM"))
      .onSnapshot(
        (snapshot) => {
          if (snapshot.exists) {
            const data = snapshot.data();

            const lastMonth = {
              ...data,
              id: snapshot.id, // References the month UID
              ...(data?.date && {
                date: moment(data.date?.toDate && data.date.toDate())
                  .utc()
                  .utcOffset(moment().utcOffset(), true),
              }),
            };
            dispatch(fetchLastMonthSuccess(lastMonth));
          }
          dispatch(setLoadingLastMonth(false));
        },
        (error) => {
          dispatch(setLoadingLastMonth(false));
          captureException("Error fetching last month onSnapshot", error);
          console.log("Error fetching last month onSnapshot: " + error);
        }
      );
    unsubscribers.push(lastMonthSubscriber);
  } catch (error) {
    dispatch(setLoadingLastMonth(false));
    captureException("Error fetching last month", error);
    console.log("Error fetching last month: " + error);
  }
};

export const fetchCurrentMonth = () => (dispatch) => {
  try {
    const userUid = auth.currentUser.uid;

    dispatch(setLoadingCurrentMonth(true));
    dispatch(fetchCurrentMonthSuccess(null));

    const currentMonthSubscriber = firestore
      .collection("months")
      .doc(userUid)
      .collection("months")
      .doc(moment().format("YYYYMM"))
      .onSnapshot(
        (snapshot) => {
          if (snapshot.exists) {
            const data = snapshot.data();

            const currentMonth = {
              ...data,
              id: snapshot.id, // References the month UID
              ...(data?.date && {
                date: moment(data.date?.toDate && data.date.toDate())
                  .utc()
                  .utcOffset(moment().utcOffset(), true),
              }),
            };
            dispatch(fetchCurrentMonthSuccess(currentMonth));
          }
          dispatch(setLoadingCurrentMonth(false));
        },
        (error) => {
          dispatch(setLoadingCurrentMonth(false));
          captureException("Error fetching current month onSnapshot", error);
          console.log("Error fetching current month onSnapshot: " + error);
        }
      );
    unsubscribers.push(currentMonthSubscriber);
  } catch (error) {
    dispatch(setLoadingCurrentMonth(false));
    captureException("Error fetching current month", error);
    console.log("Error fetching current month: " + error);
  }
};

// This is used to close onSnapshots every time the date is changed while navigating months
export const unsubscribeMonths = () => {
  unsubscribersMonth.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribersMonth = [];
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeMonthActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
