import React, { useCallback } from "react";
import moment from "moment-timezone";
import Linkify from "react-linkify";

// Analytics
import { analytics } from "../../api/firebase";

// Firestore
import { updateCoach } from "../../api/firestore/coaches";

// Components
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  Divider,
} from "@mui/material";

import { USER_TYPE, colors } from "../../api/constants";
import "./style.scss";

const InfoOverlay = (props) => {
  const theme = useTheme();

  const { coach } = props;

  // Hyperlinks
  const componentDecorator = (href, text, key) => (
    <a
      href={href}
      key={key}
      target="_blank"
      rel="noreferrer"
      style={{
        color: theme.palette.primary.main,
      }}
    >
      {text}
    </a>
  );

  const onDonePressed = useCallback(() => {
    props.handleConfirmation && props.handleConfirmation();
    props.setIsVisible(false);
  }, []);

  return (
    <Dialog
      open={props.isVisible}
      onClose={() => props.setIsVisible(false)}
      TransitionComponent={Slide}
      maxWidth="xs"
      fullWidth
      slotProps={{
        backdrop: {
          style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Adjust the alpha (last value) to make it darker or lighter
        },
      }}
    >
      {props.title ? (
        <DialogTitle className="modal-title">
          <label className="modal-title-label">{props.title}</label>
        </DialogTitle>
      ) : null}

      <DialogContent sx={{ padding: 0 }}>
        {props.isCoachInfoOverlay ? (
          <div className="coach-img-and-info-modal-container">
            {coach?.fullImage ? (
              <div className="coach-img-container">
                <img className="coach-img" src={coach?.fullImage} alt="Coach" />
              </div>
            ) : (
              <div className="coach-img-container-no-img" />
            )}

            <div
              className="name-and-bio-container"
              style={{ backgroundColor: theme.palette.quaternary.main }}
            >
              {coach?.name ? (
                <div>
                  <label
                    className="coach-name"
                    style={{ color: theme.palette.text.main }}
                  >
                    {coach?.name}
                  </label>
                </div>
              ) : null}
              {coach?.description ? (
                <div className="coach-description-container">
                  <label
                    className="coach-description"
                    style={{ marginTop: 20, color: theme.palette.text.main }}
                  >
                    {coach.description}
                  </label>
                </div>
              ) : null}

              {coach?.status &&
              props.userType &&
              props.userType !== USER_TYPE.INACTIVE ? (
                <div className="coach-status-img-container">
                  <div
                    className={
                      theme.palette.mode === "dark"
                        ? "coach-status-img-container-with-border-dark-mode"
                        : "coach-status-img-container-with-border-light-mode"
                    }
                    onClick={() => {
                      props.setIsVisible(false);
                      props.setIsCoachStatusOverlayVisible(true);

                      // Mark coach status as seen
                      updateCoach(props.coach?.id, {
                        status: {
                          seen: [...(coach?.status?.seen ?? []), props.userId],
                        },
                      });

                      // Log analytics event
                      analytics.logEvent("opened_coach_status", {
                        source: "coachProfile",
                      });
                    }}
                    // FIXED BORDER
                    style={{
                      background:
                        coach?.status &&
                        props.userType &&
                        props.userType !== USER_TYPE.INACTIVE
                          ? `linear-gradient(${theme.palette.secondaryBackground.main}, ${theme.palette.secondaryBackground.main}) padding-box,
                    linear-gradient(to bottom, ${colors.milestoneGradientFirst}, ${colors.milestoneGradientSecond}) border-box`
                          : "transparent",
                    }}
                  >
                    <img
                      className="coach-status-img"
                      src={coach?.status?.image}
                      alt="coach"
                      height="60"
                      width="60"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <div
          className="info-overlay-content"
          style={{
            marginBottom: props.subtitle || props.isCoachInfoOverlay ? 25 : 0,
          }}
        >
          {props.subtitle ? (
            <Linkify
              componentDecorator={(href, text, key) =>
                componentDecorator(href, text, key)
              }
            >
              <p className="subtitle">{props.subtitle}</p>
            </Linkify>
          ) : null}

          {coach?.latestMilestone ? (
            <>
              <label
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                LATEST MILESTONE
                {/* TODO - Add date of latest milestone */}
              </label>
              <label className="text">{coach.latestMilestone}</label>
            </>
          ) : null}

          {coach?.nextTarget ? (
            <>
              <label
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                NEXT TARGET
              </label>
              <label className="text">{coach.nextTarget}</label>
            </>
          ) : null}

          {coach?.favouriteQuote ? (
            <>
              <label
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                FAVORITE QUOTE
              </label>
              <label className="text">{coach.favouriteQuote}</label>
            </>
          ) : null}

          {coach?.funFact ? (
            <>
              <label
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                FUN FACT
              </label>
              <label className="text">{coach.funFact}</label>
            </>
          ) : null}

          {coach?.coachingApproach ? (
            <>
              <label
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                COACH APPROACH
              </label>
              <label className="text">{coach.coachingApproach}</label>
            </>
          ) : null}

          {coach?.location ? (
            <>
              <label
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                LOCATION
              </label>
              <label className="text">{coach.location}</label>
            </>
          ) : null}

          {coach?.timezone ? (
            <>
              <label
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                CURRENT TIME
              </label>
              <label className="text">
                {moment().tz(coach?.timezone).format("h:mm a")}
              </label>
            </>
          ) : null}

          {/* TODO - Tag dependent */}
          {/* {coach?.experiencedWith ? (
            <>
              <label
                className="text-title"
                style={{ color: theme.palette.gray.main }}
              >
                EXPERIENCED WITH
              </label>
              <label className="text">{coach.experiencedWith}</label>
            </>
          ) : null} */}
        </div>
      </DialogContent>

      <Divider />

      <div className="modal-footer" onClick={onDonePressed}>
        <Button
          sx={{
            borderRadius: 0,
            height: 50,
          }}
          color="primary"
          onClick={() => {
            props.handleConfirmation && props.handleConfirmation();
            props.setIsVisible(false);
          }}
        >
          {props.textOk || "Done"}
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(InfoOverlay);
