import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { debounce } from "lodash";

// Components
import { FiTrash2, FiCalendar, FiCopy } from "react-icons/fi";
import { MdOutlinePalette } from "react-icons/md";
import {
  useTheme,
  IconButton,
  TextField,
  Divider,
  Tooltip,
} from "@mui/material";

import DateTimePickerOverlay from "../../../components/DateTimePickerOverlay";
import TagPickerOverlay from "../../../components/TagPickerOverlay";
import ScreenHeader from "../../../components/ScreenHeader";

// Firebase
import {
  addInbox,
  updateInbox,
  deleteInbox,
} from "../../../api/firestore/inboxes";
import { addGoal } from "../../../api/firestore/goals";

// Analytics
import { analytics } from "../../../api/firebase";

// Own api
import { tagColors, Devices } from "../../../api/constants";
import "./style.scss";

const EditInboxScreen = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const currentDevice = useSelector((state) => state.local.currentDevice);
  const today = useSelector((state) => state.day.today);
  const inboxes = useSelector((state) => state.inbox.inboxes);

  const [inbox, setInbox] = useState({
    ...location.state.goalToEdit,
    ...(location.state.goalToEdit.createdAt && {
      createdAt: moment(location.state.goalToEdit.createdAt),
    }), // is received as "date" and converted back into moment
    ...(location.state.goalToEdit.updatedAt && {
      updatedAt: moment(location.state.goalToEdit.updatedAt),
    }), // is received as "date" and converted back into moment
  });
  const [date, setDateChange] = useState(
    location.state.goalToEdit.date
      ? moment(location.state.goalToEdit.date)
          .utc()
          .utcOffset(moment().utcOffset(), true)
      : moment()
    // is received as moment.toString() in utc and converted back into moment with the corresponding offset
  );
  const [tag, setTagChange] = useState(inbox.tag);
  const [name, setNameChange] = useState(inbox.name);
  const [description, setDescriptionChange] = useState(inbox.description);

  const [isTagPickerOverlayVisible, setIsTagPickerOverlayVisible] =
    useState(false);
  const [isDateTimePickerOverlayVisible, setIsDateTimePickerOverlayVisible] =
    useState(false);

  const handleUpdate = (item) => {
    if (!item.date) {
      updateInbox(item);
      setInbox(item);
    } else {
      const { id, newDate, createdAt, updatedAt, ...newGoal } = item; // Remove variables from inbox not necessary for goal
      addGoal(newGoal);
      deleteInbox(item);
      navigate("/inbox");
      toast.success("Moved to goals!");
    }
  };

  const handleDelete = (item) => {
    deleteInbox(item);
    navigate("/inbox", {
      state: {
        goal: {
          ...item,
          createdAt: item?.createdAt?.toDate && item.createdAt.toDate(),
          updatedAt: new Date(),
        },
      },
    });
  };

  const handleDuplicate = async (item) => {
    let duplicatedInboxId = await addInbox({
      name: (item.name || "(No title)") + " COPY",
      isDone: false,
      position: inboxes.length.toString(),
      tag: tag,
      description: item.description,
    });

    analytics.logEvent("used_duplicate_btn");

    navigate("/inbox", {
      state: {
        duplicatedInboxId: duplicatedInboxId,
      },
    });
  };

  // -------------------------------- Debounce controlled inputs -------------------------------- //
  const updateNameOrDescription = useCallback(
    debounce((property, value) => {
      handleUpdate({
        ...inbox,
        [property]: value,
      });
    }, 300),
    [inbox]
  );

  const btnBackClicked = () => {
    navigate("/inbox");
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        navigate("/inbox");
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="edit-inbox-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
        height: currentDevice !== Devices.PHONE && "100vh",
        overflowY: currentDevice !== "PHONE" && "scroll",
      }}
    >
      <div className="edit-inbox-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Undated"
          title="Edit Undated"
        />

        <Divider />

        <div className="body">
          <div className="edit-btns">
            <div className="edit-btns-first-section">
              <Tooltip title="Date">
                <button
                  className="btn-edit"
                  onClick={() => setIsDateTimePickerOverlayVisible(true)}
                >
                  <FiCalendar style={{ color: theme.palette.gray.dark }} />
                  <label
                    className="btn-edit-date"
                    style={{ color: theme.palette.gray.dark }}
                  >
                    Undated
                  </label>
                </button>
              </Tooltip>

              <Divider
                className="divider"
                sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
                orientation="vertical"
                flexItem
              />

              <Tooltip title="Color">
                <IconButton
                  className="btn-edit"
                  style={{ fontSize: 24 }}
                  onClick={() => setIsTagPickerOverlayVisible(true)}
                >
                  <MdOutlinePalette
                    className="tag-icon"
                    style={{
                      color: !inbox.tag
                        ? theme.palette.gray.dark
                        : tagColors[inbox.tag - 1].color,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>

            <div className="edit-btns-first-section">
              <Tooltip title="Duplicate">
                <IconButton
                  className="btn-edit"
                  onClick={() => handleDuplicate(inbox)}
                >
                  <FiCopy style={{ color: theme.palette.gray.dark }} />
                </IconButton>
              </Tooltip>
              <Divider
                className="divider"
                sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
                orientation="vertical"
                flexItem
              />
              <Tooltip title="Delete">
                <IconButton
                  className="btn-edit"
                  onClick={() => handleDelete(inbox)}
                >
                  <FiTrash2 style={{ color: theme.palette.gray.dark }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Divider />

          <div className="title-container">
            {tag ? (
              <div
                className="inbox-tag"
                style={{
                  backgroundColor: tag
                    ? tagColors[tag - 1].color
                    : "transparent",
                }}
              />
            ) : null}

            <TextField
              type="text"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: {
                  display: "flex",
                  color: theme.palette.text.main,
                  fontSize: 20,
                  fontWeight: "bold",
                },
              }}
              inputProps={{
                maxLength: 140,
              }}
              fullWidth
              autoComplete="off"
              multiline
              id="goalNameInput"
              placeholder="Add goal name..."
              value={name}
              onChange={(e) => {
                setNameChange(e.target.value);
                updateNameOrDescription("name", e.target.value);
              }}
              className="goal-name"
              style={{
                paddingLeft: tag ? 10 : 0,
                color: theme.palette.text.main,
              }}
              required
            />
          </div>

          <Divider />

          <div className="goal-description-section">
            <label
              className="goal-description-title"
              style={{ color: theme.palette.text.main }}
            >
              Goal description
            </label>
            <TextField
              type="text"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: { color: theme.palette.text.main },
              }}
              sx={{ marginTop: 1.5 }}
              fullWidth
              autoComplete="off"
              multiline
              minRows={10}
              id="goalDescriptionInput"
              placeholder="When, where and how will you get this done?"
              value={description}
              onChange={(e) => {
                setDescriptionChange(e.target.value);
                updateNameOrDescription("description", e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      {/* Overlays */}
      <TagPickerOverlay
        isVisible={isTagPickerOverlayVisible}
        setIsVisible={setIsTagPickerOverlayVisible}
        tag={tag}
        setTagChange={setTagChange}
        goal={inbox}
        handleUpdate={handleUpdate}
      />
      <DateTimePickerOverlay
        isVisible={isDateTimePickerOverlayVisible}
        setIsVisible={setIsDateTimePickerOverlayVisible}
        date={date} // Pass as moment obj
        minDate={today?.isSubmitted ? moment().add(1, "days") : moment()}
        setDateChange={setDateChange}
        goal={inbox}
        handleUpdate={handleUpdate}
      />
    </div>
  );
};

export default React.memo(EditInboxScreen);
