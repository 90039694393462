import React from "react";
import moment from "moment";

import { Dialog, Slide } from "@mui/material";

import { howLongAgo } from "../../api/helper";
import { lightTheme } from "../../api/theme";
import "./style.scss";

const StatusOverlay = ({ isVisible, setIsVisible, status }) => {
  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      TransitionComponent={Slide}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Adjust the alpha (last value) to make it darker or lighter
        },
      }}
    >
      <div className="status-modal-container">
        <img className="status-img" src={status?.image} alt="Coach status" />

        <div
          className="status-text-container"
          style={{ backgroundColor: lightTheme.palette.quaternary.main }}
        >
          {status?.title ? (
            <div>
              <span
                className="status-title"
                style={{ color: lightTheme.palette.text.main }}
              >
                {status?.title}
              </span>
            </div>
          ) : null}
          {status?.dateTime ? (
            <span
              className="status-dateTime"
              style={{ color: lightTheme.palette.text.main }}
            >
              {howLongAgo(
                moment(
                  status.dateTime &&
                    status.dateTime.toDate &&
                    status.dateTime.toDate()
                )
              )}
            </span>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
};

export default React.memo(StatusOverlay);
