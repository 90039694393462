import { firestore, auth } from "../firebase";
import { deleteMessage } from "./chats";
import { deleteTarget, getOriginalTargetId } from "./targets";
import { getTimestamp } from "../helper";
import moment from "moment";

import { captureException } from "@sentry/react";

export const addMonth = (date = moment()) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date).format("YYYYMM");

    const month = {
      isSubmitted: false,
      date: getTimestamp(moment(date).startOf("month").utcOffset(0, true)),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new day
    const monthRef = firestore
      .collection("months")
      .doc(userUid)
      .collection("months")
      .doc(id);
    batch.set(monthRef, month, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting month transaction", error);
      console.error("Error while commiting month transaction: " + error);
    });
  } catch (error) {
    captureException("Error while adding month", error);
    console.error("Error while adding month: " + error);
  }
};

export const addMonthAwait = async (date = moment()) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date).format("YYYYMM");

    const month = {
      isSubmitted: false,
      date: getTimestamp(moment(date).startOf("month").utcOffset(0, true)),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new day
    const monthRef = firestore
      .collection("months")
      .doc(userUid)
      .collection("months")
      .doc(id);
    batch.set(monthRef, month, { merge: true });

    // ---- Finish transaction ----
    await batch.commit().catch((error) => {
      captureException("Error while commiting month transaction", error);
      console.error("Error while commiting month transaction: " + error);
    });
  } catch (error) {
    captureException("Error while adding month", error);
    console.error("Error while adding month: " + error);
  }
};

export const updateMonth = (date = moment(), monthReceived) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date).format("YYYYMM");

    const month = {
      clientComment: monthReceived.clientComment,
      date: getTimestamp(moment(date).startOf("month").utcOffset(0, true)),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new month
    const monthRef = firestore
      .collection("months")
      .doc(userUid)
      .collection("months")
      .doc(id);
    batch.set(monthRef, month, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting month transaction", error);
      console.error("Error while commiting month transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating month", error);
    console.error("Error while updating month: " + error);
  }
};

export const undoSubmitMonthResults = async (date, month) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date).format("YYYYMM");

    const unsubmittedMonth = {
      isSubmitted: false, // Set back to false
      date: getTimestamp(moment(date).startOf("month").utcOffset(0, true)),
      movedTargets: [], // Set movedTargets back to none, because none would have been moved
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new month
    const monthRef = firestore
      .collection("months")
      .doc(userUid)
      .collection("months")
      .doc(id);
    batch.set(monthRef, unsubmittedMonth, { merge: true });

    // ---- Delete messages ----
    if (month && month.messageId) {
      deleteMessage({ id: month.messageId }, false);
    }
    if (month && month.imagesId && month.imagesId.length) {
      month.imagesId.forEach((imageId) => {
        deleteMessage({ id: imageId }, false);
      });
    }

    // ---- Delete moved targets ----
    if (month && month.movedTargets && month.movedTargets.length) {
      month.movedTargets.forEach(async (movedTarget) => {
        try {
          const originalTargetId = await getOriginalTargetId(movedTarget);

          if (originalTargetId) {
            firestore
              .collection("targets")
              .doc(userUid)
              .collection("targets")
              .where(
                "date",
                ">=",
                moment().startOf("month").utcOffset(0, true).toDate()
              )
              .where("id", "==", originalTargetId)
              .get()
              .then(async (targetsToDelete) => {
                targetsToDelete.docs.map((target) =>
                  deleteTarget({ id: target.id })
                );
              })
              .catch((error) => {
                captureException("Error fetching targets to delete", error);
                console.log("Error fetching targets to delete: " + error);
              });
          }
        } catch (error) {
          captureException("Error while fetching targets to delete", error);
          console.error("Error while fetching targets to delete: " + error);
        }
      });
    }

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting undo submit month transaction",
        error
      );
      console.error(
        "Error while commiting undo submit month transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while submitting undo submit month", error);
    console.error("Error while submitting undo submit month: " + error);
  }
};

export const autoSubmitMonths = async () => {
  try {
    const userUid = auth.currentUser.uid;
    const lastMonth = moment()
      .subtract(1, "months")
      .startOf("month")
      .utcOffset(0, true)
      .toDate();

    // Get unsubmitted months older than last month
    const unsubmittedMonthsQuery = await firestore
      .collection("months")
      .doc(userUid)
      .collection("months")
      .where("isSubmitted", "==", false)
      .where("date", "<", lastMonth)
      .get();

    const unsubmittedMonths = unsubmittedMonthsQuery.docs.map((doc) => {
      return {
        id: doc.id,
        date: moment(doc.data().date?.toDate && doc.data().date.toDate())
          .utc()
          .utcOffset(moment().utcOffset(), true),
      };
    });

    if (unsubmittedMonths.length === 0) {
      console.log("No unsubmitted months found.");
      return;
    }

    // Start transaction
    const batch = firestore.batch();

    // Check month targets are going to move to
    // 1) If this month is not submitted, roll over targets to this month.
    // 2) If this month is already submitted, roll over targets to next month.
    let monthToRollOverTargetsTo;
    const isThisMonthSubmitted = await isMonthSubmitted();
    monthToRollOverTargetsTo = moment()
      .add(isThisMonthSubmitted ? 1 : 0, "months")
      .startOf("month");
    // Create month to which targets will move to, if it's not created already.
    const isMonthToRollOverTargetsToCreated = await isMonthCreated(
      monthToRollOverTargetsTo
    );
    if (!isMonthToRollOverTargetsToCreated) {
      addMonth(monthToRollOverTargetsTo);
    }

    // Loop through unsubmitted months and get their undone targets
    for (const month of unsubmittedMonths) {
      const targetsQuery = await firestore
        .collection("targets")
        .doc(userUid)
        .collection("targets")
        .where("isDone", "==", false)
        .where("date", ">=", moment(month.date).utcOffset(0, true).toDate())
        .where(
          "date",
          "<",
          moment(month.date).add(1, "months").utcOffset(0, true).toDate()
        )
        .get();

      const targets = targetsQuery.docs
        .map((doc) => {
          // Here it's not necessary to convert (like in fetchMonths()) because we don't handle using moment or anything. We just get them in timestamp, and reupload without viewing or modifying anything

          return {
            ...doc.data(),
            id: doc.data().id || doc.id, // doc.data().id if target is copy of original (rolled more than once). Or target.id if target IS the original (first time rolled)
          };
        })
        .filter(
          (el) => el.isRolledOver === true || el.isRolledOver === undefined
        );

      if (targets.length > 0) {
        for (const target of targets) {
          const newMovedTargetRef = firestore
            .collection("targets")
            .doc(userUid)
            .collection("targets")
            .doc();

          batch.set(newMovedTargetRef, {
            ...target,
            date: getTimestamp(
              moment(monthToRollOverTargetsTo)
                .startOf("month")
                .utcOffset(0, true)
            ),
            isPartiallyDone: false,
          });
        }

        batch.update(firestore.doc(`months/${userUid}/months/${month.id}`), {
          isSubmitted: true,
          movedTargets: targets.map((target) => target.id),
          lastSubmissionType: "auto",
        });
      } else {
        batch.update(firestore.doc(`months/${userUid}/months/${month.id}`), {
          isSubmitted: true,
          lastSubmissionType: "auto",
        });
      }
    }

    await batch.commit();
    console.log("Month autosubmit completed.");
  } catch (error) {
    captureException("Error while submitting months", error);
    console.error("Error while submitting months: " + error);
  }
};

export const isMonthCreated = async (date = moment()) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date)
      .startOf("month")
      .utcOffset(0, true)
      .format("YYYYMM");

    return await firestore
      .collection("months")
      .doc(userUid)
      .collection("months")
      .doc(id)
      .get()
      .then((query) => {
        if (query.exists) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        captureException("Error checking if this month is instantiated", error);
        console.log("Error checking if this month is instantiated: " + error);
      });
  } catch (error) {
    captureException(
      "Error while checking if this month is instantiated",
      error
    );
    console.log("Error while checking if this month is instantiated: " + error);
  }
};

export const isMonthSubmitted = async (date = moment()) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date)
      .startOf("month")
      .utcOffset(0, true)
      .format("YYYYMM");

    const monthDoc = await firestore
      .collection(`months/${userUid}/months`)
      .doc(id)
      .get();

    if (monthDoc.exists) {
      return monthDoc.data()?.isSubmitted || false;
    } else {
      return false;
    }
  } catch (error) {
    captureException("Error while checking if this month is submitted", error);
    console.log("Error while checking if this month is submitted: " + error);
    return false;
  }
};

export const instantiateMonth = (id, isSubmitted) => {
  try {
    const userUid = auth.currentUser.uid;

    const dateToAdd = moment().set({
      year: id.substring(0, 4),
      month: id.substring(4, 6) - 1,
    });

    const month = {
      isSubmitted: isSubmitted,
      date: getTimestamp(moment(dateToAdd).startOf("month").utcOffset(0, true)),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new day
    const monthRef = firestore
      .collection("months")
      .doc(userUid)
      .collection("months")
      .doc(id);
    batch.set(monthRef, month, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting month transaction", error);
      console.error("Error while commiting month transaction: " + error);
    });
  } catch (error) {
    captureException("Error while adding month", error);
    console.error("Error while adding month: " + error);
  }
};
